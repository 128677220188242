export const environment = {
  production: false,
  apiUrl: "https://camkeeper-api-dev.armadilloamarillo.cloud/api",
  assetsUrl: "https://camkeeper-api-dev.armadilloamarillo.cloud",
  emailRegex: new RegExp(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  ),
  phoneRegex: new RegExp(/^(?:6[0-9]|7[1-9]|9[1-9])[0-9]{7}$/),
  passwordRegex: new RegExp(/^(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
};
